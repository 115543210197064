import Image from "next/image";
import styles from "./Header.module.css";
import { useState, useEffect, useRef } from "react";
import { removeCookie } from '@/libs/helpers/Cookie'
import { useGlobalContext } from "@/libs/context/GlobalContext";
import { useRouter } from 'next/router';
import Select from "../select/Select";
import GlobalError from "@/components/popups/globalError/GlobalError"
import jsPDF from "jspdf";

export function Header() {
  const [open, setOpen] = useState(false);
  const { props: { selectedBoqVersion, pageURL, setLoading, boqVersions, isValid, setIsValid, isOnline, syncing, enableEditing, incoId ,errorList} } = useGlobalContext()
  const query = pageURL.split('?')[1] || '';
  const router = useRouter();
  const roomName = (router.query.roomname as string)?.replaceAll('-', ' ') || '';
  const logoutRef = useRef<HTMLDivElement>(null);
  const isHomePage = pageURL.includes('/home');
  const selectedBoqVersionId = router.query.version || selectedBoqVersion.id || ''
  const [selectedBoq, setselectedBoq] = useState<any>({
    id: -1,
    name: "NA",
    isActive: false
  })
  const defaultVersion = {
    id: -1,
    name: "NA",
    isActive: false
  }

  const logOut = () => {
    setLoading(true)
    removeCookie('loginToken')
    localStorage.clear()
    setLoading(false)
    router.push(`/login${query ? `?${query}` : ''}`);
  }

  useEffect(() => {
    if (boqVersions.length > 0) {
      const selectedVersion = boqVersions.find((version: any) => version.id == selectedBoqVersionId);
      setselectedBoq(selectedVersion);
    } else {
      setselectedBoq(defaultVersion)
    }
  }, [selectedBoqVersion])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const goToHome = () => {
    const params = query.split('&').map(param => param.split('='));
    const projectParam = params.find(([key]) => key === 'project');
    const userTypeParam = params.find(([key]) => key === 'usertype');
  
    // Start with the base path
    let newPath = '/home';
  
    // Add parameters if they exist
    const queryParams = [];
    if (projectParam) {
      queryParams.push(projectParam.join('='));
    }
    if (userTypeParam) {
      queryParams.push(userTypeParam.join('='));
    }
  
    // Construct the new URL with the parameters
    if (queryParams.length > 0) {
      newPath += '?' + queryParams.join('&');
    }
  
    // Navigate to the new URL
    router.push(newPath);
  }
  const removeTags = (str:any) => {
    return str.replace(/<[^>]*>/g, '');
  };

  const downloadErrorList = () => {
    const doc = new jsPDF();
    doc.setFont("Helvetica", "normal");
    const pageHeight = doc.internal.pageSize.height - 10;
    const lineHeight = 10;
  
    // Helper function to write text and handle page breaks
    const writeText = (text: string, yPos: number, fontSize: number = 12) => {
      doc.setFontSize(fontSize);
      const wrappedText = doc.splitTextToSize(text, 180);
      let currentY = yPos;
  
      wrappedText.forEach((line: string) => {
        if (currentY + lineHeight > pageHeight) {
          doc.addPage();
          currentY = 10;
        }
        doc.text(line, 10, currentY);
        currentY += lineHeight;
      });
  
      return currentY;
    };
  
    // Group errors by designer message first, then by type
    const groupedErrors = errorList?.reduce((acc: any, data: any) => {
      if (!acc[data.msg]) {
        acc[data.msg] = {};
      }
      
      data.errorList.forEach((error: any) => {
        if (!acc[data.msg][error.type]) {
          acc[data.msg][error.type] = [];
        }
        acc[data.msg][error.type].push(removeTags(error.error));
      });
      
      return acc;
    }, {});
  
    // Write title
    let yOffset = writeText("Error List", 10, 16);
    yOffset += lineHeight * 1.5;
  
    // Iterate through designer messages
    Object.entries(groupedErrors).forEach(([msg, typeGroups]: [string, any], msgIndex: number) => {
      // Write designer message as main heading
      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      if (yOffset + lineHeight > pageHeight) {
        doc.addPage();
        yOffset = 10;
      }
      yOffset = writeText(`Designer Message: ${msg}`, yOffset);
      yOffset += lineHeight;
  
      // Iterate through error types within this message
      Object.entries(typeGroups).forEach(([type, errors]: [string, any], typeIndex: number) => {
        // Write error type as subheading
        doc.setFontSize(13);
        doc.setFont("Helvetica", "bold");
        yOffset = writeText(`Type: ${type}`, yOffset);
        yOffset += lineHeight / 2;
  
        // Write individual errors
        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");
        errors.forEach((error: string, index: number) => {
          yOffset = writeText(`• ${error}`, yOffset);
          
          // Add small spacing between errors
          if (index < errors.length - 1) {
            yOffset += lineHeight / 2;
          }
        });
  
        // Add spacing between different error types
        if (typeIndex < Object.keys(typeGroups).length - 1) {
          yOffset += lineHeight;
        }
      });
  
      // Add larger spacing between different designer messages
      if (msgIndex < Object.keys(groupedErrors).length - 1) {
        yOffset += lineHeight * 1.5;
      }
    });
  
    doc.save("error_list.pdf");
  };

  return (
    <div className={styles.logoHeadBox}>
      <div className={styles.leftLogos}>
        <figure className={`${styles.logoBx} pointer`} onClick={() => goToHome()}>
          <Image
            src="/assets/images/Blocks-dummy-logo.svg"
            width={36}
            height={30}
            alt="logo"
          />
        </figure>
        <figure className={styles.logoBx}>
          <Image
            src="/assets/images/DQC.svg"
            alt="logo"
            width={40}
            height={30}
            className="ml5"
          />
        </figure><div className="dFlex flexCol alignCenter" style={{ borderLeft: "0.5px solid #b0b0b0", height: "50px", justifyContent: "center" }}>
          {!isHomePage && roomName && <div className={styles.roomName}>{roomName}</div>}
          {<div className={styles.incoId}>{incoId}</div>}
        </div>
        {!isHomePage && enableEditing && <div className={styles.labels}>View Only Mode</div>}
      </div>
      <div className="dFlex alignCenter">
        {!isHomePage && <><figure className={styles.cloudSync}>
          <Image
            src={`/assets/images/${syncing ? 'cloud_not_sync.svg' : 'cloud_sync.svg'}`}
            alt="logo"
            width={20}
            height={30}
          />
        </figure> <span className={styles.syncStatus}>{syncing ? 'Syncing...' : 'Saved...'}</span></>}
        {isHomePage && <div className={`${styles.boqVersion} mr10`}>
          <Select type="Versions" data={boqVersions} selectedName={selectedBoqVersion.name} />
        </div>}
        {!isHomePage && selectedBoq?.name && <div className={styles.boqVersionBox}>{selectedBoq.name}</div>}
        {/* {!isHomePage && <div className={styles.errorList} onClick={downloadErrorList}>Error List</div>} */}
        {!isHomePage && <figure title="Error List" onClick={()=>{errorList.length && downloadErrorList()}} className={errorList.length ? styles.errorList : styles.errorListDis}>
          <Image
            src={`/assets/images/${errorList.length ? "errorList" : "errorListDis"
              }.svg`}
            width={20}
            height={18}
            alt="error"
          />
        </figure>}
        {!isHomePage && <figure className={styles.cloudSync}>
          <Image
            src={`/assets/images/net-${isOnline ? "online" : "offline"
              }.svg`}
            width={20}
            height={18}
            alt="internet"
          />
        </figure>}
        <div
          className={styles.bg_col}
          onClick={() => setOpen(!open)}
          ref={logoutRef}
          title="LogOut"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.3 6.133a3.2 3.2 0 1 1 6.4 0 3.2 3.2 0 0 1-6.4 0zm3.2-1.6a1.6 1.6 0 1 0 0 3.2 1.6 1.6 0 0 0 0-3.2z"
              fill="#666"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.012 13.798A8 8 0 1 1 2.987 2.203a8 8 0 0 1 11.026 11.595zm-10.486-1.77a6.4 6.4 0 1 1 9.948 0A7.5 7.5 0 0 0 8.5 10.133a7.5 7.5 0 0 0-4.974 1.895zm1.16 1.112A6.371 6.371 0 0 0 8.5 14.4c1.43 0 2.749-.469 3.814-1.26A5.9 5.9 0 0 0 8.5 11.733a5.899 5.899 0 0 0-3.814 1.407z"
            />
          </svg>
        </div>
      </div>

      {open && (
        <ul className={styles.login_signUp}>
          <li className="font10" onClick={logOut}>Logout</li>
        </ul>
      )}
      {isValid &&
        <GlobalError setPopupMessage={setIsValid} message="ProjectId or RoomId or VersionId Missing" svgIcon="requestRaiseError" />
      }
    </div>
  );
}
