import Image from 'next/image';
import styles from './GalleryViewPopup.module.css';
import { IGalleryImages } from '@/interfaces/IHomeContent';
import { useEffect, useState } from 'react';
import { useCanvasContext } from '@/libs/context/CanvasContext';
type props = {
    setIsShowImagePopup: Function
    roomImages: any
    selectImageIdx: number
    handleGalleryPopup: Function
}
export function GalleryViewPopup({ setIsShowImagePopup, roomImages, selectImageIdx, handleGalleryPopup }: props) {
    const [imageIdx, setImageIdx] = useState(selectImageIdx);
    const { setUploadedImage } = useCanvasContext();
    const totalImages = roomImages.galleries.length;
    const getImage = (idx: number) => {
        return roomImages.galleries[idx].filePath
    };

    const handlePrev = () => {
        const prevIndex = (imageIdx - 1 + totalImages) % totalImages;
        setImageIdx(prevIndex);
    };

    const handleNext = () => {
        const nextIndex = (imageIdx + 1) % totalImages;
        setImageIdx(nextIndex);
    };

    const isFirstImage = imageIdx === 0;
    const isLastImage = imageIdx === totalImages - 1;

    const handleAddToSlide = () => {
        setUploadedImage!(`https://img.squareyards.com/${getImage(imageIdx)}`)
        handleGalleryPopup()
    }
    return <div className={styles.editerPopupmnbox}>
        <div className={styles.editerPoplatinerbox}>
            <div className={styles.editerpopInermnbox}>
                <Image width={800} height={500} src={`https://img.squareyards.com/${getImage(imageIdx)}`} alt="" />
                <div className={styles.popRgtletpopbox} style={isFirstImage ? { justifyContent: 'flex-end' } : { justifyContent: 'space-between' }}>
                    {!isFirstImage && <button onClick={handlePrev}><Image width={10} height={20} src="/assets/images/Left-blue-arrow.svg" alt="" /></button>}
                    {!isLastImage && <button className={styles.active} onClick={handleNext}><Image width={10} height={20} src="/assets/images/Right-bluearrow.svg" alt="" /></button>}
                </div>
                <div className={styles.crosbtboxpop} onClick={() => setIsShowImagePopup(false)}>
                    <button><Image width={12} height={12} src="/assets/images/crobtpopup.svg" alt="" /></button>
                </div>
                <div className={styles.editAddsildebt} onClick={handleAddToSlide}>
                    <button>+ Add to Slide</button>
                </div>
            </div>
        </div>
    </div>
}