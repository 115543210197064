import axios from "axios";
import { jwtDecode } from "jwt-decode";

export function getKeyByValue(EnumObject: any, value: string) {
  const indexOfS = Object.values(EnumObject).indexOf(
    value as unknown as typeof EnumObject
  );
  const key = Object.keys(EnumObject)[indexOfS];
  return key;
}
export function formatDate(date: string) {
  const _date = new Date(date);
  const options: any = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = _date.toLocaleDateString("en-US", options);
  return formattedDate;
}
export function sendMail(body: string, fileData: File | null) {
  const isSendMailEnabled = Number(process.env.IS_ERROR_MAIL_ENABLED || 0);
  const mailsIds = process.env.ERROR_SEND_MAILS?.split(',');

  if (isSendMailEnabled === 1 && mailsIds && mailsIds.length > 0) {
    const formData = new FormData();

    formData.append('subject', '2DD email');
    formData.append('mailBody', body);

    mailsIds.forEach(email => {
      formData.append('emailIds[]', email);
    });

    if (fileData) {
      formData.append('attachment', fileData, fileData.name);
    }

    axios.post(
      `${process.env.INCO_CONTENT_API_BASE_URL}v1/send-mail/commonSendMail`,
      formData
    ).catch((err) => {
      console.error("Failed to send error email:", err);
    });
  }
}
export const formDataToJsonString = (formData: FormData|any) => {
  if(formData instanceof FormData){
    const object: { [key: string]: string } = {};
    formData.forEach((value, key) => {
      object[key] = value.toString();
    });
    return JSON.stringify(object);
  }else{
    return JSON.stringify(formData);
  } 
};
export const decodeToken = (token: string) => {
  try {
    const decodedToken: any = jwtDecode(token);
    return decodedToken
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};
export function formatTimestamp(timestamp:number) {
  // Create a new Date object with the provided timestamp
  const date = new Date(timestamp);

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  // Extract time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Combine date and time into a readable format
  const readableDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return readableDate;
}
export function pushRoute(Route: string | any) {
  if (typeof window != undefined) {
    window.location.href = Route;
  }
}
export function debounce(fn: Function, ms = 300) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}
export const avatarLetter = (name: string) => {
  // Check if the name is provided and is not an empty string
  if (name && name.trim() !== "") {
    // Get the first character of the name and convert it to uppercase
    return name.trim()[0].toUpperCase();
  }
  // If the name is not provided or is an empty string, return an empty string
  return "";
};
export const getStatus = (status: string) => {
  switch (status) {
    case "Open":
      return "#FF8A00";
    case "Approved":
      return "#17a8e5";
    case "Resolved":
      return "#38B84D";
    case "Cancelled":
      return "#EC3C3C";
    default:
      return "";
  }
};
export const roomStatusIcon = (status: any) => {
  switch (status) {
    case "Open":
      return "roomOpen.svg";
    case "Approved":
      return "roomApproved.svg";
    case "Resolved":
      return "roomResolved.svg";
    case "No Issue Reported":
      return "NoIssue.svg";
    default:
      return "roomOpen.svg";
  }
};
export const moduleIcon = (name: string) => {
  switch (name) {
    case "Modular":
      return "modulerKit.svg";
    case "Store Product":
      return "storeKit.svg";
    case "Services":
      return "serviceKit.svg";
    case "NSD":
      return "nsdKit.svg";
  }
};
export const getRoomStatus = (room: any) => {
  if (room) {
    if (
      room.openCount == 0 &&
      room.resolvedCount == 0 &&
      room.approvedCount == 0 &&
      room.cancelledCount == 0
    ) {
      return "No Issue Reported";
    } else if (room.openCount > 0) {
      return "Open";
    } else if (
      room.openCount == 0 &&
      room.resolvedCount > 0 &&
      room.approvedCount == 0
    ) {
      return "Resolved";
    } else if (
      room.openCount == 0 &&
      room.resolvedCount == 0 &&
      room.approvedCount > 0
    ) {
      return "Approved";
    } else {
      return "Open";
    }
  } else {
    return "No Issue Reported";
  }

  // return room
};
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export function fileExtension(filename: string) {
  const fileType = filename.substring(filename.lastIndexOf("."));

  if (fileType === ".pdf") {
    return "assets/images/pdfIcon.svg";
  } else if (
    fileType === ".docx" ||
    fileType === ".txt" ||
    fileType === ".txt"
  ) {
    return "assets/images/txtIcon.svg";
  } else if (
    fileType === ".jpg" ||
    fileType === ".jpeg" ||
    fileType === ".png"
  ) {
    return "assets/images/imageIcon.svg";
  }
}
export const formatDateWithDate = (timestamp: any) => {
  const date = new Date(timestamp);

  // Format the time
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const time = `${hours % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes} ${
    hours >= 12 ? "pm" : "am"
  }`;

  // Format the date
  const options: any = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };
  const formattedDate = date.toLocaleString("en-US", options);

  return `${time}, ${formattedDate}`;
}; 
export const waitForImagesToLoad = (element:any) => {
  const images = element.getElementsByTagName('img');
  const promises = [];
  for (let i = 0; i < images.length; i++) {
    const img = images[i];
    if (!img.complete) {
      promises.push(
        new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        })
      );
    }
  }
  return Promise.all(promises);
};

export function getUniqueValue(input: string): string {
  // Split the input string by comma
  const values = input.split(',');
  // Filter out duplicates by using an array method
  const uniqueValues = values.filter((value, index, self) => self.indexOf(value.trim()) === index);
  // Return the first unique value, trimmed of any leading or trailing whitespace
  return uniqueValues[0].trim();
}

 // Check if token is expired
 export const isTokenExpired = (token: string) => {
  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};

export function getUniqueGalleryImages(galleryImages: any[]) {
  const uniqueFilePaths = new Set<string>(); // To track unique file paths
  const result = galleryImages?.map((room) => {
      const uniqueGalleries = room?.galleries?.filter((gallery: any) => {
          if (!uniqueFilePaths.has(gallery.filePath)) {
              uniqueFilePaths.add(gallery.filePath);
              return true;
          }
          return false;
      });
      return {
          ...room,
          galleries: uniqueGalleries, // Update the galleries with unique images
      };
  });
  return result?.filter((room) => room.galleries.length > 0); // Return only rooms with galleries
}