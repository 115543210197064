import { createContext, useContext } from 'react';
import { IGlobalContent } from '../../interfaces';

export const GlobalContext = createContext<IGlobalContent>({
    props: {
        setLoading: (a: Boolean) => { },
        loading:false,
        setDownloading: (a: Boolean) => { },
        pageURL: '',
        role: '',
        setRole: (a: string) => { },
        incoId: '',
        setIncoId: (a: string) => { },
        apiError: '',
        setApiError: (a: string) => { },
        loginRole: '',
        setLoginRole: (a: string) => { },
        module: '',
        setModule: (a: string) => { },
        boqVersions: [],
        setBoqVersions: (a: string) => { },
        latestBoqVersion: '',
        setLatestBoqVersion: (a: string) => { },
        currentBoqVersion: '',
        setCurrentBoqVersion: (a: string) => { },
        selectedBoqVersion: '',
        setSelectedBoqVersion: (a: string) => { },
        showReqBtn: false,
        showUpdateBtns: false,
        setShowReqBtn: (a: string) => { },
        setShowUpdateBtns: (a: string) => { },
        dqcStage: 1,
        setDqcStage: (a: number) => { },
        slideCount: 0,
        setSlideCount: (a: number) => { },
        apiFlag: false,
        setApiFlag: (a: Boolean) => { },
        commentFlag: true,
        setCommentFlag: (a: Boolean) => { },
        commentsFlag: false,
        setCommentsFlag: (a: Boolean) => { },
        basePath: '',
        dqcStatus: '',
        setDqcStatus: (a: string) => { },
        updateJsonError : '',
        setUpdateJsonError: (a: string) => { },
        zoomState: '',
        setZoomState: (a: string) => { },
        dqcStatusMessage: '',
        setDqcStatusMessage: (a: string) => { },
        enableImageDelete: '',
        setEnableImageDelete: (a: string) => { },
        enableTableDelete: '',
        setEnableTableDelete: (a: string) => { },
        isValid: false,
        setIsValid: (a: Boolean) => { },
        startZooming: false,
        setStartZooming: (a: Boolean) => { },
        syncing: false,
        setSyncing: (a: Boolean) => { },
        enableEditing: true,
        setEnableEditing: (a: boolean) => { },
        reloadPopup: false,
        setReloadPopup: (a: boolean) => { },
        isOnline: false,
        editorZoomState: '',
        setEditorZoomState: (a: any) => { },
        pdfZoomState: '',
        setPdfZoomState: (a: any) => { },
        selectedZoomTarget: '',
        setSelectedZoomTarget: (a: any) => { },
        errorList: '',
        setErrorList: (a: any) => { },
        transformComponentRef:"",
        pdfTransformComponentRef:"",
        setReLogin:(a: any) => { }
    }
})
export const useGlobalContext = () => useContext(GlobalContext)