import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import styles from "./StatusTab.module.css";
import { useCanvasContext } from "@/libs/context/CanvasContext";
import { getStatus, avatarLetter } from "../../../libs/helpers/common";
import { useGlobalContext } from "../../../libs/context/GlobalContext"
import { useRouter } from "next/router";
import { v4 as uuidv4 } from 'uuid';

type props = {
  filteredComments: any;
  setActiveLowerBox: Function;
  activeLowerBox: number;
  slideId: string,
};
export function StatusTab({ filteredComments, setActiveLowerBox, activeLowerBox, slideId }: props) {
  const { comments, setComments ,updateCommentsJson} = useCanvasContext();
  const [showOptions, setShowOptions] = useState(false);
  const [commentOptions, setCommentOptions] = useState<any>({});
  const { props: { role, latestBoqVersion, commentsFlag } } = useGlobalContext()
  const router = useRouter()
  const selectedVersionId = router.query.version
  const [hideComments, setHideComments] = useState<any>({})
  const [userName, setUserName] = useState("User");
  const [userID, setUserID] = useState(-1);

  useEffect(() => {
    if (typeof window !== 'undefined') { // Check if window is defined (client-side)
      const userDetailsString = localStorage.getItem("userDetails");
      const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
      const name = userDetails ? userDetails.displayName : "User";
      const id = userDetails ? userDetails.employeeId : -1;
      setUserName(name);
      setUserID(id);
    }
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputMsgMap, setInputMsgMap] = useState<{ [key: string]: string }>({});


  // Function to toggle the options for a specific comment
  const toggleCommentOptions = (commentId: any) => {
    setCommentOptions((prevState: any) => ({
      ...prevState,
      [commentId]: !prevState[commentId]
    }));
  };

  const changeStatus = (e: any, com: any) => {
    e.stopPropagation();
    const status = e.target.innerText.trim();

    // If status is "Reject", change it to "Open"
    const newStatus = status === "Reject" ? "Open" : status;

    setCommentOptions((prevState: any) => ({
      ...prevState,
      [com.id]: false
    }));

    const updatedComments = comments.map((comment: any) => {
      const updatedData = comment.data.map((comSt: any) => {
        if (comSt.id === com.id) {
          updateCommentsJson!([{ ...comSt, status: newStatus }],comment.slideId)
          return { ...comSt, status: newStatus };
        }
        return comSt;
      });
      return { ...comment, data: updatedData };
    });
    setShowOptions(false);
    setComments && setComments(updatedComments);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const threeDot = document.querySelector(`.${styles.threeDot}`);
      const commentSec = document.querySelector(`.${styles.statusCommentBox}`);

      // Check if the click is outside the threeDot icon and outside the commentSec area
      if (threeDot && !threeDot.contains(event.target as Node) && commentSec && !commentSec.contains(event.target as Node)) {
        // Close the options
        setCommentOptions({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = (e: any) => {
    setActiveLowerBox(e.id)
  }
  const handleCommentsVisibility = (msgId: any) => {
    setHideComments((prevState: any) => ({
      ...prevState,
      [msgId]: !prevState[msgId]
    }));
  }

  const handleReply = (com: any) => {
    const newMessage = {
      id: uuidv4(),
      user: userName,
      userId: userID,
      text: inputMsgMap[com.id],
    };

    // Find the index of the slide in comments array
    const existingSlideIndex = comments && comments.findIndex((comment: any) => comment.slideId === com.slideId);

    if (existingSlideIndex !== -1 && existingSlideIndex !== null) {
      // Slide exists, update its data
      const existingSlide = comments[existingSlideIndex];
      const existingDataIndex = existingSlide.data.findIndex((data: any) => data.id === com.id);
      if (existingDataIndex !== -1) {
        // Data entry exists, add the new message to it
        const updatedComments = [...comments];
        const existingData = updatedComments[existingSlideIndex].data[existingDataIndex];
        existingData.messages.push(newMessage);
        setComments && setComments(updatedComments);
        updateCommentsJson!([existingData],existingSlide.slideId)
      }
    }

    setInputMsgMap((prevInputMsgMap) => ({
      ...prevInputMsgMap,
      [com.id]: '',
    }));
    if (inputRef.current) {
      inputRef.current.focus()
    }
  };

  return (
    <div className={styles.statusCommentBox}>
      {filteredComments.map((commentGroup: any, index: number) => (
        <div key={index} className={styles.commentsWrapper}>
          {commentGroup?.data?.map((comment: any) => (
            <div key={comment.id} className={styles.commentSec}
            // onClick={() => handleClick(comment)}
            >
              <div className={styles.commentBox}>
                <div className={styles.userStatBox}>
                  <div className={styles.userStat}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6 0C2.69 0 0 2.69 0 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z"
                        fill={getStatus(comment.status)}
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.902 3.977a.376.376 0 0 1 0 .53L5.387 8.023a.375.375 0 0 1-.53 0L3.099 6.265a.376.376 0 0 1 0-.53.376.376 0 0 1 .53 0l1.493 1.493 3.25-3.25a.372.372 0 0 1 .53 0z"
                        fill="#fff"
                      />
                    </svg>
                    <Image
                      className={styles.threeDot}
                      src="/assets/images/threeDot.svg"
                      width={10}
                      height={4}
                      alt="threeDot"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        toggleCommentOptions(comment.id)

                      }}
                    />
                    {(commentOptions[comment.id] && !commentsFlag && latestBoqVersion.id == selectedVersionId) && (
                      <div className={`${styles.commBoxclikbt}`}>
                        <div className={styles.tollsubboxm}>
                          <div
                            className={styles.tollbtbtcling}
                            onClick={(e: any) => changeStatus(e, comment)}
                          >
                            <figure>
                              {role === 'DESIGNER' && <span className="font10 text000 dispBlok whiteNrp">
                                Open
                              </span>}
                              {role === 'DQC' && <span className="font10 text000 dispBlok whiteNrp">
                                Reject
                              </span>}
                            </figure>
                          </div>
                          {role === "DESIGNER" && <div
                            className={styles.tollbtbtcling}
                            onClick={(e: any) => changeStatus(e, comment)}
                          >
                            <figure>
                              <span className="font10 text000 dispBlok whiteNrp">
                                Resolved
                              </span>
                            </figure>
                          </div>}
                          {role === "DQC" && <div
                            className={styles.tollbtbtcling}
                            onClick={(e: any) => changeStatus(e, comment)}
                          >
                            <figure>
                              <span className="font10 text000 dispBlok whiteNrp">
                                Approved
                              </span>
                            </figure>
                          </div>}
                          {role === "DQC" && <div
                            className={styles.tollbtbtcling}
                            onClick={(e: any) => changeStatus(e, comment)}
                          >
                            <figure>
                              <span className="font10 text000 dispBlok whiteNrp">
                                Cancelled
                              </span>
                            </figure>
                          </div>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {hideComments[comment.id] && comment?.messages?.map((msg: any, index: number) => (
                  <div key={msg.id} className={styles.msgs} onClick={() => handleClick(comment)}>
                    <div className={styles.user}>
                      <div className={`${styles.avatar} ${comment.dqcStage == 1 ? 'bgPrimary' : comment.dqcStage == 2 ? 'bgSecondary' :'bgPrimary'}`}>{avatarLetter(msg?.user) || 'J'}</div>
                      <span className={styles.name}>
                        {msg?.user}
                      </span>
                    </div>
                    <p className={styles.comment}>{msg?.text}</p>
                  </div>
                ))}
                {!hideComments[comment.id] && <div className={styles.msgs} onClick={() => handleClick(comment)}>
                  <div className={styles.user}>
                    <div className={`${styles.avatar} ${comment.dqcStage == 1 ? 'bgPrimary' : comment.dqcStage == 2 ? 'bgSecondary' :'bgPrimary'}`}>{avatarLetter(comment?.messages[0]?.user) || 'J'}</div>
                    <span className={styles.name}>
                      {comment?.messages[0]?.user}
                    </span>
                  </div>
                  <p className={styles.comment}>{comment?.messages[0]?.text}</p>
                </div>}
              </div>
              {!commentsFlag && latestBoqVersion.id == selectedVersionId && <div className={styles.lowerRlybox}>
                <input
                  className="font10"
                  autoFocus
                  type="text"
                  name=""
                  id={comment.id}
                  value={inputMsgMap[comment.id] ?? ''}
                  placeholder="comment"
                  onChange={(e) => setInputMsgMap((prevInputMsgMap) => ({
                    ...prevInputMsgMap,
                    [comment.id]: e.target.value,
                  }))}
                />
                <div className={`${styles.lowerBoximg} ${comment.dqcStage == 1 ? 'bgPrimary' : comment.dqcStage == 2 ? 'bgSecondary' :'bgPrimary'}`}
                  onClick={() => inputMsgMap[comment.id].trim() !== '' ? handleReply(comment) : undefined}
                >
                  <img src="/assets/images/replyarrow.svg" alt="" />
                </div>
              </div>}
              {!hideComments[comment.id] && <span className={styles.replyStat} onClick={() => handleCommentsVisibility(comment.id)}>

                View  {comment?.messages?.length > 1
                  ? comment?.messages?.length - 1
                  : ""}{" "} Replies
              </span>}
              {hideComments[comment.id] && <span className={styles.replyStat} onClick={() => handleCommentsVisibility(comment.id)}>
                Hide Replies
              </span>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
