import { DrawingAPI, LibraryAPI } from "../helpers/http-client";
import { getUniqueValue } from "../helpers/common";

class libraryService {
  uploadImages = async (
    image: Express.Multer.File | any,
    // token: string,
    projectId?: any
  ) => {
    try {
      const formData: any = new FormData();
      const blob = new Blob([image.buffer], {
        type: image.mimetype,
      });
      formData.append("File", blob, image.originalname);
      projectId && formData.append("ProjectId", projectId);
      !projectId && formData.append("ConvertToWebp", true);
      const result = await LibraryAPI.post("twodd/UploadFile", formData, {
        headers: {
          // Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (result && result.status === 200) {
        return result.data;
      } else {
        console.error("Upload failed with status:", result.status);
        return { message: "Upload failed" };
      }
    } catch (error) {
      console.error("Error during upload:", error);
      return { message: "Internal Server Error" };
    }
  };

  getGallery = async (projectId: string) => {
    try {
      const result = await LibraryAPI.get(
        `roomGallery/getGalleries?projectId=${projectId}`
      );
      if (result && result.status === 200) {
        return result.data.data;
      } else;
      {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  getSupportedDocs = async (projectId: any) => {
    try {
      const result = await LibraryAPI.get(
        `TwoDD/GetFiles?projectId=${projectId}`
      );
      if (result && result.status === 200) {
        return result.data;
      } else;
      {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  getJson = async (
    roomId: number,
    project: number,
    token: string,
    module: string
  ) => {
    try {
      const result = await DrawingAPI.get(
        `editorjson/getjson?projectId=${project}&roomId=${roomId}&type=${getUniqueValue(
          module
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result && result.status === 200) {
        return result.data.data;
      } else;
      {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getErrorList = async (
    roomId: number,
  ) => {
    try {
      const result = await DrawingAPI.get(
        `mainjson/getErrorMsgList?roomId=${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.STATIC_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result && result.status === 200) {
        return result.data.data;
      } else;
      {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  CheckRoomVersionStatus = async (roomId: any) => {
    try {
      const result = await LibraryAPI.get(
        `twodd/CheckRoomVersionStatus?roomId=${roomId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result && result.status == 200) {
        return result.data;
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  getPdfJson = async (roomId: number, project: number, module: string) => {
    try {
      const result = await DrawingAPI.get(
        `editorjson/getjsonNoAuth?projectId=${project}&roomId=${roomId}&type=${getUniqueValue(
          module
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result && result.status === 200) {
        return result.data.data;
      } else;
      {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  getRooms = async (projectId: string, token:string, boqVersionId: string) => {
    try {
      const response = await DrawingAPI.get(`editorjson/getCommentsStatus`, {
        params: { projectId, boqVersionId },
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        return response.data || [];
      } else {
        console.error("Unexpected response status:", response.status);
        return [];
      }
    } catch (error: any) {
      console.error(
        "Error fetching rooms:",
        error.response?.data?.message || error.message
      );
      throw new Error(error.response?.data?.message || error.message);
    }
  };

  getBOQVersions = async (projectId: any, usertype: string, userId: number) => {
    try {
      const result = await LibraryAPI.get(
        `TwoDD/Master?projectId=${projectId}&usertype=${usertype}&UserId=${userId}`
      );
      if (result && result.status === 200) {
        return result.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  updateSlideStatus = async (id: string, slideId: string, token: string) => {
    try {
      const payload = {
        id,
        slideId,
      };
      const result = await DrawingAPI.post(
        "editorjson/updateSlideStatus",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result && result.status === 200) {
        return result.data;
      } else {
        console.error("Exclude slide failed with status:", result.status);
        return { message: "Exclude slide failed" };
      }
    } catch (error) {
      console.error("Error Excluding slide :", error);
      return { message: "Internal Server Error" };
    }
  };
  addAnnotation = async (
    id: string,
    slideId: string,
    groupId: string,
    data: any,
    token: string,
  ) => {
    try {
      const payload = {
        id,
        slideId,
        groupId,
        data,
      };
        const result = await DrawingAPI.post(
          "editorjson/addAnnotation",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (result && result.status == 200) {
          return result.data;
        } else {
          console.error("Add annotation failed with status:", result);
          return { message: "Add annotation failed" };
        }
    } catch (error) {
      console.error("Error adding annotation:", error);
      return { message: "Internal Server Error" };
    }
  };
  addCustomSlide = async (
    id: number,
    slideId: string,
    order: any,
    token: string,
  ) => {
    try {
      const payload = {
        id,
        slideId,
        order
      };
        const result = await DrawingAPI.post(
          "editorjson/addCustomSlide",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (result && result.status==200) {
          return result.data;
        } else {
          console.error("Add annotation failed with status:", result.status);
          return { message: "Add Custom slide failed failed" };
        }
    } catch (error) {
      console.error("Error adding Custom slide :", error);
      return { message: "Internal Server Error" };
    }
  };
  addComments = async (
    id: number,
    slideId: number,
    groupId: string,
    data: any,
    token: string,
  ) => {
    try {
      const payload = { id, slideId, groupId, data };
      const result = await DrawingAPI.post(
        "editorjson/addComments",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      console.error("Error adding comments:", error.response);
      if (error.response?.status === 401) {
        return { status: 401, message: "Unauthorized access" };
      }
      return { message: "Internal Server Error", status: error.response?.status };
    }
  };
  deleteCustomSlide = async (
    id: string,
    slideId: string,
    token: string,
  ) => {
    try {
      const payload = {
        id: id,
        slideId: slideId,
      };

        const result = await DrawingAPI.post("editorjson/deleteCustomSlide", 
          payload,
          {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (result && result.data.success) {
          return result.data;
        } else {
          return { message: "Delete tools failed" };
        }
    } catch (error) {
      console.error("Error deleting tools:", error);
      return { message: "Internal Server Error" };
    }
  };

  deleteTools = async (
    roomId: string,
    slideId: string,
    annotationIds: any[],
    token: string,
  ) => {
    try {
      const payload = {
        roomId: roomId,
        slideId: slideId,
        annotationIds: annotationIds,
      };

        const result = await DrawingAPI.post("editorjson/deleteAnnotation", 
          payload, // Include payload here for DELETE requests
          {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (result) {
          return result.data;
        } else {
          console.error("Delete tools failed with status:", result);
          return { message: "Delete tools failed" };
        }
    } catch (error) {
      console.error("Error deleting tools:", error);
      return { message: "Internal Server Error" };
    }
  };

  deleteFile = async (fileId: string) => {
    try {
      const response = await LibraryAPI.post(
        "twodd/deleteFile",
        { FileId: fileId },
        {
          headers: {
            // Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      return response;
    } catch (error: any) {
      console.error(
        "Error deleting file:",
        error.response ? error.response.data : error.message
      );
      throw new Error(
        error.response ? error.response.data.message : error.message
      );
    }
  };

  raiseDqcRequest = async (
    payload: {
      projectId: string;
      UserId: number;
    }
    // loginToken: string
  ) => {
    try {
      const result = await LibraryAPI.post(`DQC/RaiseDQCRequest`, payload, {
        headers: {
          // Authorization: loginToken,
          "Content-Type": "application/json",
        },
      });

      if (result && result.status === 200) {
        return result.data;
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  UpdateDQCStatus = async (
    payload: {
      projectId: string;
      type: string;
      UserId: number;
    }
    // loginToken: string
  ) => {
    try {
      const result = await LibraryAPI.post(`DQC/UpdateDQCStatus`, payload, {
        headers: {
          // Authorization: loginToken,
          "Content-Type": "application/json",
        },
      });
      if (result && result.status === 200) {
        return result.data;
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getDQCHistory = async (projectId: string, userId: number) => {
    try {
      const result = await LibraryAPI.get(
        `dqc/GetDQCHistory?projectId=${projectId}&UserId=${userId}`
      );
      if (result && result.status === 200) {
        return result.data;
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getPdf = async (jobId: any) => {
    try {
      const result = await LibraryAPI.get(
        `${process.env.PDF_API_BASEURL}pdf/status/${jobId}`,
        {
          responseType: "arraybuffer",
          headers: {
            Accept: 'application/pdf,application/json',
          }
        }
      );
      
      const contentType = result.headers["content-type"];
      
      if (result.status === 200) {
        if (contentType.includes("application/pdf")) {
          // Ensure we're returning a proper Buffer
          return { 
            status: "completed", 
            data: Buffer.from(result.data)
          };
        } else {
          // Handle processing status
          const textData = Buffer.from(result.data).toString('utf8');
          try {
            const jsonData = JSON.parse(textData);
            return { status: "processing", data: jsonData };
          } catch {
            return { status: "processing" };
          }
        }
      }
      return { status: "error", message: "Failed to retrieve PDF status" };
    } catch (error) {
      console.error("Error fetching PDF status:", error);
      return { status: "error", message: error };
    }
  };
}

export default new libraryService();
